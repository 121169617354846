import React, { useState, useEffect, useRef } from 'react';
import chatFlow from '../../utils/Chatflow';
import { useSelector } from 'react-redux';
import client from '../../api';
import Urls from '../../api/urls';
import * as sdk from "microsoft-cognitiveservices-speech-sdk";
// import emailjs from 'emailjs-com';

const timeLogs = {};

const getCurrentTime = () => new Date().getTime();

const logTime = (questionId) => {
  timeLogs[questionId] = getCurrentTime();
};

const getTimeDifference = (id1, id2) => {
  if (timeLogs[id1] && timeLogs[id2]) {
    return (timeLogs[id2] - timeLogs[id1]) / (1000 * 60 * 60);
  }
  return 0;
};

const handleNextQuestion = (currentId) => {
  const timeDiff = getTimeDifference(5, 48);
  if (timeDiff <= 24) {
    return 62;
  }
  return chatFlow.en.find((q) => q.id === currentId)?.options[0]?.nextId || null;
};

const sessionRanges = {
  1: { start: 1, end: 48 },
  2: { start: 49, end: 85 },
  3: { start: 86, end: 114 },
  4: { start: 115, end: 150 },
  5: { start: 151, end: 191 },
  6: { start: 192, end: 235 },
  7: { start: 236, end: 261 },
  8: { start: 262, end: 282 }
};

const getSession = (questionId) => {
  return Object.keys(sessionRanges).find(session => {
    const { start, end } = sessionRanges[session];
    return questionId >= start && questionId <= end;
  });
};

const getProgress = (questionId) => {
  const session = getSession(questionId);
  if (session) {
    const { start, end } = sessionRanges[session];
    return ((questionId - start) / (end - start)) * 100;
  }
  return 0;
};

const Chatbot = () => {

  const voices = {
    'en-US': [
      { name: 'en-US-JennyNeural', label: 'Jenny' },
    ],
    'es-MX': [
      { name: 'es-MX-DaliaNeural', label: 'Dalia' },
    ],
    'es-US': [
      { name: 'es-US-AlonsoNeural', label: 'Alonso' },
    ],
    'zh-TW': [
      { name: 'zh-TW-HsiaoChenNeural', label: 'Hsiao Chen' },
    ],
  };

  const [showTextInput, setShowTextInput] = useState(false);
  const [userTextInput, setUserTextInput] = useState('');
  const [currentQuestionId, setCurrentQuestionId] = useState(null);
  const [chatHistory, setChatHistory] = useState([]);
  const [voice, setVoice] = useState('en-US-JennyNeural');
  const [selectedVoice, setSelectedVoice] = useState(null);
  const [language, setLanguage] = useState('en-US');
  const [userResponses, setUserResponses] = useState({});
  const [userInput, setUserInput] = useState('');
  const [waitingForInput, setWaitingForInput] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [isVoiceEnabled, setIsVoiceEnabled] = useState(false);
  const [volume, setVolume] = useState(1);
  const [speed, setSpeed] = useState(1);
  const [textSize, setTextSize] = useState(16);
  const [isTextInputVisible, setIsTextInputVisible] = useState(false);
  const [showLanguageDropdown, setShowLanguageDropdown] = useState(false);
  const [responses, setResponses] = useState({});
  const [showVoiceDropdown, setShowVoiceDropdown] = useState(false);
  const [showSpeedDropdown, setShowSpeedDropdown] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [player, setPlayer] = useState(null);
  const [synthesizer, setSynthesizer] = useState(null);
  const [showSupportForm, setShowSupportForm] = useState(false);
  const [supportForm, setSupportForm] = useState({
    name: '',
    phone: '',
    email: '',
    message: ''
  });
  const [supportMessageSent, setSupportMessageSent] = useState(false);
  const [isButtonsVisible, setIsButtonsVisible] = useState(false);
  const chatWindowRef = useRef(null);
  const chatData = useSelector((state) => state.chat.data);
  const questionSequence = [74, 76, 78, 80, 86, 104, 107, 131, 139, 146, 168, 176, 182, 200, 232, 237, 239, 241, 250, 252,263, 264];
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const speechConfig = sdk.SpeechConfig.fromSubscription(process.env.REACT_APP_AZURE_KEY, process.env.REACT_APP_REGION);
    speechConfig.speechSynthesisVoiceName = voice;
    speechConfig.speechSynthesisRate = speed;

    const player = new sdk.SpeakerAudioDestination();
    setPlayer(player)
    const audioConfig = sdk.AudioConfig.fromSpeakerOutput(player);
    const synthesizer = new sdk.SpeechSynthesizer(speechConfig, audioConfig);
    setSynthesizer(synthesizer);
  }, [voice, speed])

  useEffect(() => {
    const { last_session_date, nextId } = chatData;
    const lastSessionDate = new Date(last_session_date);
    const todayDate = new Date(new Date().toLocaleDateString());
    updateChatHistory();
    if (lastSessionDate < todayDate) {
      const isPHQComplete = (nextId < 7 || (10 <= nextId && nextId <= 19) || nextId === 47);
      if (!isPHQComplete) {
        chatFlow[language] = chatFlow[language].map((step) => {
          if (step.id === 7) {
            step.options[0].nextId = nextId
          }
          return step;
        });
      }
    } else {
      setCurrentQuestionId(nextId);
    }
  }, [chatData.messages]);

  const updateChatHistory = () => {
    const { messages, nextId } = chatData;
    const chatIds = messages?.map((message) => message.system);
    chatIds.push(nextId);
    const history = chatFlow[language].filter((q) => chatIds.includes(q.id));
    const historyMap = new Map(history.map(item => [item.id, item]));
    const orderedHistory = chatIds.map(id => historyMap.get(id)).filter(item => item !== undefined);
    const chatMessages = orderedHistory.map((message) => ({text: message.text, options: message.options }));
    setChatHistory(chatMessages);
  }
  
  const handleTextInputSubmit = () => {
    console.log('User input:', userTextInput);
    const userResponse = userTextInput;
    setUserTextInput('');
    setShowTextInput(false);
    const currentIndex = questionSequence.indexOf(currentQuestionId);
    const nextId = currentQuestionId + 1;
    storeChat(nextId, userResponse);

    setChatHistory([...chatHistory, { text: userResponse, user: true }]);
    setProgress(getProgress(nextId));  // Update progress

    if (currentIndex !== -1 && currentIndex < questionSequence.length - 1) {
      const nextQuestionId = nextId;
      setCurrentQuestionId(nextQuestionId);

      const nextQuestion = chatFlow[language].find((q) => q.id === nextQuestionId);
      if (nextQuestion) {
        setChatHistory([
          ...chatHistory,
          { text: userResponse, user: true },
          { text: nextQuestion.text, options: nextQuestion.options },
        ]);
      }
    } else {
      setCurrentQuestionId(nextId);
      const nextQuestion = chatFlow[language].find((q) => q.id === nextId);
      if (nextQuestion) {
        setChatHistory([
          ...chatHistory,
          { text: userResponse, user: true },
          { text: nextQuestion.text, options: nextQuestion.options },
        ]);
      }
    }
  };

  const handleAnswer = (questionId, answer) => {
    setResponses((prevResponses) => ({
      ...prevResponses,
      [questionId]: parseInt(answer),
    }));
    let nextId = getNextQuestionId(questionId, answer);
    setCurrentQuestionId(nextId);
  };

  const getNextQuestionId = (questionId, answer) => {
    const { nextId } = chatData;
    if (questionId === 5 || questionId === 6) {
      const response5 = responses[5];
      const response6 = responses[6];
      if (response5 !== undefined && response6 !== undefined) {
        const cumulativeValue = response5 + response6;
        return cumulativeValue < 2 ? 7 : 10;
      }
    }
    if (questionId === 10 || questionId === 11 || questionId === 12 || questionId === 13 || questionId === 14 || questionId === 15 || questionId === 16 || questionId === 17) {
      const response10 = responses[10];
      const response11 = responses[11];
      const response12 = responses[12];
      const response13 = responses[13];
      const response14 = responses[14];
      const response15 = responses[15];
      const response16 = responses[16];
      const response17 = responses[17];

      if (response10 !== undefined && response11 !== undefined && response12 !== undefined && response13 !== undefined && response14 !== undefined && response15 !== undefined && response16 !== undefined && response17 !== undefined) {
        const cumulativeValuephq8 = response10 + response11 + response12 + response13 + response14 + response15 + response16 + response17;
        return cumulativeValuephq8 < 1 ? 20 : 7;
      }
    }
    const questionSequence = [74, 76, 78, 80, 86, 104, 107, 131, 139, 146, 168, 176, 182, 200, 232, 237, 240, 242, 251, 254, 264, 265];
    
    const currentIndex = questionSequence.indexOf(currentQuestion.id);
    if (currentIndex !== -1 && currentIndex < questionSequence.length - 1) {
      return questionSequence[currentIndex + 1];
    }
    return (
      chatFlow.en.find((q) => q.id === questionId)?.options.find((option) => option.text === answer)?.nextId || questionId
    );
  };

  useEffect(() => {
    if (chatHistory.length > 0 && isVoiceEnabled) {
      const lastMessage = chatHistory[chatHistory.length - 1];
      readOutLoud(lastMessage.text);
    }
  }, [chatHistory, isVoiceEnabled]);

  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [chatHistory]);

  const handleUserResponse = (questionId, response) => {
    setUserResponses((prevResponses) => ({
      ...prevResponses,
      [questionId]: response,
    }));
  };

  const readOutLoud = async (texts) => {    
    const textArray = Array.isArray(texts) ? texts : [texts];
    const fullText = textArray.join(' ');
    setIsSpeaking(true);

    const ssml = `
    <speak version="1.0" xmlns="http://www.w3.org/2001/10/synthesis" xml:lang="en-US">
      <voice name="${voice}">
        <prosody rate="${speed}">
          ${fullText}
        </prosody>
      </voice>
    </speak>`;

    synthesizer.speakSsmlAsync(
        ssml,
        result => {
            if (result.reason === sdk.ResultReason.SynthesizingAudioCompleted) {
                console.log('Speech synthesized successfully.');
            } else {
                console.error(`Speech synthesis failed: ${result.errorDetails}`);
            }
        },
        error => {
            console.error(`Error: ${error}`);
            setIsSpeaking(false);
            synthesizer.close();
        }
    );
  };

  const storeChat = async(nextId, optionText) => {
    await client.post(Urls.UPDATE_CHAT, {
      email: localStorage.getItem("email"),
      message: {system: currentQuestionId, user: optionText },
      last_session_date: new Date().toLocaleDateString(),
      nextId
    });
  } 

  const handleOptionClick = (nextId, optionText) => {
    storeChat(nextId, optionText);
    const parsedOptionValue = parseInt(optionText, 10);
    handleUserResponse(currentQuestionId, parsedOptionValue);

    setChatHistory([...chatHistory, { text: optionText, user: true }]);

    const updatedResponses = { ...userResponses, [currentQuestionId]: parsedOptionValue };
    setUserResponses(updatedResponses);

    setProgress(getProgress(nextId));  // Update progress

    const value5 = updatedResponses[5] || 0;
    const value6 = updatedResponses[6] || 0;
    const cumulativeValue = value5 + parsedOptionValue;

    if (currentQuestionId === 5) {
      setChatHistory([
        ...chatHistory,
        { text: optionText, user: true },
        {
          text: chatFlow[language].find((step) => step.id === 6).text,
          options: chatFlow[language].find((step) => step.id === 6).options,
        },
      ]);
      setCurrentQuestionId(6);
      setWaitingForInput(true);
    } else if (currentQuestionId === 6) {
      const nextStep = cumulativeValue < 2 ? 7 : 10;
      setChatHistory([
        ...chatHistory,
        { text: optionText, user: true },
        {
          text: chatFlow[language].find((step) => step.id === nextStep).text,
          options: chatFlow[language].find((step) => step.id === nextStep).options,
        },
      ]);
      setCurrentQuestionId(nextStep);
      setWaitingForInput(nextStep === 6);
      setSelectedOption(optionText);
    } else if ([10, 11, 12, 13, 14, 15, 16, 17].includes(currentQuestionId)) {
      let nextQuestionId;
      if (currentQuestionId < 17) {
        nextQuestionId = currentQuestionId + 1;
      } else {
        const questionIdsToCheck = [10, 11, 12, 13, 14, 15, 16, 17];
        const proceedTo19 = questionIdsToCheck.some(id => updatedResponses[id] > 0);
        nextQuestionId = proceedTo19 ? 19 : 7;
      }

      setChatHistory([
        ...chatHistory,
        { text: optionText, user: true },
        {
          text: chatFlow[language].find((step) => step.id === nextQuestionId).text,
          options: chatFlow[language].find((step) => step.id === nextQuestionId).options,
        },
      ]);
      setCurrentQuestionId(nextQuestionId);
      setWaitingForInput(nextQuestionId === 6);
      setSelectedOption(optionText);
    } else {
      const nextStep = chatFlow[language].find((step) => step.id === nextId);
      setChatHistory([
        ...chatHistory,
        { text: optionText, user: true },
        { text: nextStep.text, options: nextStep.options },
      ]);
      setCurrentQuestionId(nextId);
      setWaitingForInput(nextId === 6);
      setSelectedOption(optionText);
    }
  };

  const startChat = () => {
    const firstStep = chatFlow[language].find((step) => step.id === 1);
    setCurrentQuestionId(1);
    setChatHistory([...chatHistory, { text: firstStep.text, options: firstStep.options }]);
  };

  const handleUserInput = () => {
    if (userInput.trim()) {
      handleUserResponse(currentQuestionId, userInput);
      setChatHistory([
        ...chatHistory,
        { text: userInput, options: [] },
      ]);
      setUserInput('');
      setWaitingForInput(false);
      setIsTextInputVisible(false);
      const nextId = currentQuestionId + 1;
      setCurrentQuestionId(nextId);
      setProgress(getProgress(nextId));  // Update progress
      const nextQuestion = chatFlow[language].find((q) => q.id === nextId);
      if (nextQuestion) {
        setChatHistory([
          ...chatHistory,
          { text: nextQuestion.text, options: nextQuestion.options },
        ]);
      }
    }
  };

  const handleLanguageChange = async (e) => {
    setLanguage(e.target.value);
    setVoice(voices[e.target.value][0].name);
    setShowLanguageDropdown(false);
  }

  const handleVoiceChange = async (e) => {
    setVoice(e.target.value)
    setShowVoiceDropdown(false);   
  }

  const togglePlayPause = () => {
    if (isSpeaking) {
      player.pause()
      setIsSpeaking(false);
    }

    if (!isSpeaking) {
      player.resume(() => {
        console.log("Resuming speech");
        setIsSpeaking(true);
      }, (error) => console.log("Error playing", error));
    }
  };

  const handleSupportFormSubmit = (e) => {
    e.preventDefault();
    setSupportMessageSent(true);

    setTimeout(() => {
      setSupportMessageSent(false);
      setShowSupportForm(false);
    }, 5000);

    // Send support message to the provided email
    // You can use your backend service to handle this
  };

  const currentQuestion = chatFlow[language].find((q) => q.id === currentQuestionId);
  const renderChatHistory = () => {
    return chatHistory.map((entry, index) => (
      <div key={index} style={{ marginBottom: '20px', display: 'flex', position: 'relative', justifyContent: entry.user ? 'flex-end' : 'flex-start' }}>
        {!entry.user && (
          <img
            src="https://drive.google.com/thumbnail?id=1i-IeMQwo6SuFbpXJMm36kRYYPlpXiPgZ&sz=w1000"
            alt="Profile"
            style={{
              width: '42px',
              height: '42px',
              borderRadius: '50%',
              marginRight: '10px',
            }}
          />
        )}
        <div
          style={{
            background: entry.user ? '#ffcf4f' : '#ffffff',
            border: '1px solid #ddd',
            borderRadius: '8px',
            padding: '10px',
            fontFamily: 'Helvetica Neue, Arial, sans-serif',
            flex: 1,
            fontSize: `${textSize}px`,
            maxWidth: '70%',
          }}
        >
          {(Array.isArray(entry.text) ? entry.text : [entry.text]).map((line, i) => (
            <p key={i} dangerouslySetInnerHTML={{ __html: line }}></p>
          ))}
          {isVoiceEnabled && !entry.user && (
            <img
              src="https://drive.google.com/thumbnail?id=1LvMyD0PAujX1pQwdLMHuRYzzuqfQNMO6&sz=w100"
              alt="Replay"
              style={{
                width: '20px',
                height: '20px',
                position: 'absolute',
                bottom: '10px',
                right: '10px',
                cursor: 'pointer',
              }}
              onClick={() => readOutLoud(entry.text)}
            />
          )}
        </div>
      </div>
    ));
  };

  return (
    <div
      style={{
        maxWidth: '600px',
        width: '90%',
        margin: '0 auto',
        padding: '20px',
        border: '1px solid #ddd',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontFamily: 'Helvetica Neue, Arial, sans-serif',
        height: '100vh',
        overflow: 'hidden',
        backgroundColor: '#FFFFFF',
      }}
    >
      <img
        src="https://i0.wp.com/nurturcare.com/wp-content/uploads/2024/08/Purple_Horiz.png?resize=300%2C109&ssl=1"
        alt="Header"
        style={{ width: '100%', maxWidth: '150px', marginBottom: '10px' }}
      />

      <div
        style={{
          width: '100%',
          height: '20px',
          backgroundColor: '#E0E4FF',
          borderRadius: '10px',
          marginBottom: '10px',
          position: 'relative',
        }}
      >
        <div
          style={{
            width: `${progress}%`,
            height: '100%',
            backgroundColor: '#A0A8FF',
            transition: 'width 0.5s ease-in-out',
            borderRadius: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#FFFFFF',
            fontWeight: 'bold',
          }}
        >
          {Math.round(progress)}%
        </div>
      </div>

      <div
        ref={chatWindowRef}
        style={{
          width: '100%',
          flex: 1,
          display: 'flex',
          border: '1px solid #ccc',
          borderRadius: '8px',
          overflowY: 'auto',
          padding: '10px',
          backgroundColor: '#FFFFFF',
        }}
      >
        <div style={{ flex: 1, padding: '10px', backgroundColor: '#E0E4FF', borderRadius: '8px' }}>
          {renderChatHistory()}
        </div>
      </div>

      {currentQuestion ? (
        <>
          {currentQuestion.options && (
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', width: '100%', marginTop: '10px', justifyContent: 'center' }}>
              {currentQuestion.options.map((option, idx) => (
                <button
                  key={idx}
                  onClick={() => handleOptionClick(option.nextId, option.text)}
                  style={{
                    borderRadius: '8px',
                    border: '1px solid #ddd',
                    padding: '10px',
                    background: '#7B7FFF',
                    color: '#fff',
                    cursor: 'pointer',
                    fontFamily: 'Helvetica Neue, Arial, sans-serif',
                    flex: '0 1 calc(12% - 20px)',
                    maxWidth: '150px',
                    marginTop: '10px',
                    textAlign: 'center',
                  }}
                >
                  {option.text}
                </button>
              ))}
            </div>
          )}

          {questionSequence.includes(currentQuestion.id) && (
            <>
              <input
                type="text"
                value={userTextInput}
                onChange={(e) => setUserTextInput(e.target.value)}
                style={{
                  padding: '8px',
                  borderRadius: '4px',
                  border: '1px solid #ccc',
                  width: '100%',
                  fontSize: '16px',
                  marginTop: '10px',
                }}
              />
              <button
                onClick={handleTextInputSubmit}
                style={{
                  marginTop: '10px',
                  padding: '10px',
                  borderRadius: '4px',
                  background: '#7B7FFF',
                  color: '#fff',
                  cursor: 'pointer',
                  width: '100%',
                  fontFamily: 'Helvetica Neue, Arial, sans-serif',
                }}
              >
                Submit
              </button>
            </>
          )}
        </>
      ) : (
        <button
          onClick={startChat}
          style={{
            marginTop: '10px',
            borderRadius: '8px',
            border: '1px solid #ddd',
            padding: '10px',
            background: '#7B7FFF',
            color: '#fff',
            cursor: 'pointer',
            fontFamily: 'Helvetica Neue, Arial, sans-serif',
            width: '15%',
          }}
        >
          Start
        </button>
      )}

      <div style={{ position: 'fixed', bottom: '20px', right: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center', zIndex: 2 }}>
        <button
          onClick={() => setShowSupportForm((prev) => !prev)}
          style={{
            width: '50px',
            height: '50px',
            borderRadius: '50%',
            background: showSupportForm ? '#7B7FFF' : '#FFCF4F',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <img src={"https://drive.google.com/thumbnail?id=1Fk3VmsdVDkCVZosRUhbGvBAT2RILVel7&sz=w1000"} alt="Support" style={{ width: '30px', height: '30px' }} />
        </button>
        {showSupportForm && (
          <div style={{ position: 'fixed', bottom: '80px', right: '20px', background: '#fff', border: '1px solid #ddd', borderRadius: '8px', padding: '20px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', zIndex: 3 }}>
            <h3>Support Form</h3>
            <h5>You can also reach us at support@nurturcare.com</h5>
            <form onSubmit={handleSupportFormSubmit}>
              <div>
                <label>Name:</label>
                <input type="text" value={supportForm.name} onChange={(e) => setSupportForm({ ...supportForm, name: e.target.value })} required />
              </div>
              <div>
                <label>Phone:</label>
                <input type="tel" value={supportForm.phone} onChange={(e) => setSupportForm({ ...supportForm, phone: e.target.value })} required />
              </div>
              <div>
                <label>Email:</label>
                <input type="email" value={supportForm.email} onChange={(e) => setSupportForm({ ...supportForm, email: e.target.value })} required />
              </div>
              <div>
                <label>Message:</label>
                <textarea value={supportForm.message} onChange={(e) => setSupportForm({ ...supportForm, message: e.target.value })} required />
              </div>
              <button type="submit" style={{ marginTop: '10px', padding: '10px', borderRadius: '4px', background: '#7B7FFF', color: '#fff', cursor: 'pointer', width: '100%', fontFamily: 'Helvetica Neue, Arial, sans-serif' }}>
                Submit
              </button>
            </form>
            {supportMessageSent && <p style={{ marginTop: '10px', color: 'green' }}>Support message has been sent. The Nurtur team will get back to you shortly.</p>}
          </div>
        )}
      </div>

      <div style={{ position: 'fixed', bottom: '80px', right: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center', zIndex: 2 }}>
        <button
          onClick={() => setIsButtonsVisible((prev) => !prev)}
          onMouseEnter={() => setIsButtonsVisible(true)}
          style={{
            width: '50px',
            height: '50px',
            borderRadius: '50%',
            background: isButtonsVisible ? '#7B7FFF' : '#FFCF4F',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <img src={"https://drive.google.com/thumbnail?id=1ZP7JfXib4IyOigdYiTrHx1mKBEOygCee&sz=w1000"} alt="Settings" style={{ width: '30px', height: '30px' }} />
        </button>
        {isButtonsVisible && (
          <div style={{ position: 'fixed', bottom: '140px', right: '20px', display: 'flex', gap: '10px', background: '#fff', border: '1px solid #ddd', borderRadius: '8px', padding: '10px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', zIndex: 3 }}>
            <button
              onClick={() => {
                setShowLanguageDropdown(false);
                setShowSpeedDropdown(false);
                setShowVoiceDropdown(false);
                setIsVoiceEnabled((prev) => !prev);
              }}
              style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                background: isVoiceEnabled ? '#7B7FFF' : '#FFCF4F',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <img src={"https://drive.google.com/thumbnail?id=1RGuggUvWKHIt17pkhGKhUKq4svDn4h74&sz=w1000"} alt="Enable Voice" style={{ width: '30px', height: '30px' }} />
            </button>
            <button
              onClick={() => {
                setShowVoiceDropdown(false);
                setShowSpeedDropdown(false);
                setShowLanguageDropdown((prev) => !prev);
              }}
              style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                background: showLanguageDropdown ? '#7B7FFF' : '#FFCF4F',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <img src={"https://drive.google.com/thumbnail?id=1bZ5pXYyWu2DnYpkQYbo6Uq8unzB3kFJY&sz=w1000"} alt="Select Language" style={{ width: '30px', height: '30px' }} />
            </button>
            {showLanguageDropdown && (
              <div style={{ position: 'absolute', bottom: '60px', background: '#FFCF4F', border: '1px solid #ccc', borderRadius: '8px', zIndex: 1 }}>
                <select value={language} onChange={handleLanguageChange}>
                  <option value="en-US">English (US)</option>
                  <option value="es-MX">Spanish (Mexico)</option>
                  <option value="es-US">Spanish (US)</option>
                  <option value="zh-TW">Taiwanese (TW)</option>
                </select>
              </div>
            )}
            {isVoiceEnabled && (
              <>
                <button
                  onClick={() => {
                    setShowLanguageDropdown(false);
                    setShowSpeedDropdown(false);
                    setShowVoiceDropdown((prev) => !prev);
                  }}
                  style={{
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                    background: showVoiceDropdown ? '#7B7FFF' : '#FFCF4F',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                >
                  <img src={"https://drive.google.com/thumbnail?id=1AEKIKhs6RffndnEqh7sUKP5nDutOZ9hs&sz=w1000"} alt="Select Voice" style={{ width: '30px', height: '30px' }} />
                </button>
                {showVoiceDropdown && (
                  <div style={{ position: 'absolute', bottom: '60px', background: '#fff', border: '1px solid #ccc', borderRadius: '8px', zIndex: 1 }}>
                    <select
                      onChange={handleVoiceChange}
                      value={voice ? voice : ''}
                      style={{ padding: '10px', width: '100%' }}
                    >
                      {voices[language].map((v) => (
                        <option key={v.name} value={v.name}>
                          {v.label}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                <button
                  onClick={() => {
                    setShowVoiceDropdown(false);
                    setShowLanguageDropdown(false);
                    setShowSpeedDropdown((prev) => !prev)
                  }}
                  style={{
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                    background: showSpeedDropdown ? '#7B7FFF' : '#FFCF4F',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                >
                  <img src={"https://drive.google.com/thumbnail?id=1zs6tHOt-qfOCvoYBJhbiDi5z2cLy5dsT&sz=w1000"} alt="Adjust Speed" style={{ width: '30px', height: '30px' }} />
                </button>
                {showSpeedDropdown && (
                  <div style={{ position: 'absolute', bottom: '60px', background: '#fff', border: '1px solid #ccc', borderRadius: '8px', zIndex: 1 }}>
                    <select
                      onChange={(e) => {
                        setSpeed(parseFloat(e.target.value));
                        setShowSpeedDropdown(false);
                      }}
                      value={speed}
                      style={{ padding: '10px', width: '100%' }}
                    >
                      <option value="5">5x</option>
                      <option value="4">4x</option>
                      <option value="3">3x</option>
                      <option value="2">2x</option>
                      <option value="0.5">0.5x</option>
                      <option value="0.7">0.7x</option>
                      <option value="0.8">0.8x</option>
                      <option value="0.9">0.9x</option>
                      <option value="1">1x</option>
                    </select>
                  </div>
                )}
              </>
            )}
            <button
              onClick={() => {
                setShowLanguageDropdown(false);
                setShowSpeedDropdown(false);
                setShowVoiceDropdown(false);
                togglePlayPause();
              }}
              style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                background: isSpeaking ? '#7B7FFF' : '#FFCF4F',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <img src={"https://drive.google.com/thumbnail?id=1OMK2EFI_9qgQqG1ffdowYYRBYwJDErc6&sz=w1000"} alt="Pause/Play Voice" style={{ width: '30px', height: '30px' }} />
            </button>
            <button
              onClick={() => {
                setShowLanguageDropdown(false);
                setShowSpeedDropdown(false);
                setShowVoiceDropdown(false);
                setTextSize((prev) => prev + 1);
              }}
              style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                background: '#FFCF4F',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <img src={"https://drive.google.com/thumbnail?id=14pZEsXniPPn1rgajSdY9GT5Cx_IonPYd&sz=w1000"} alt="Increase Text Size" style={{ width: '30px', height: '30px' }} />
            </button>
            <button
              onClick={() => {
                setShowLanguageDropdown(false);
                setShowSpeedDropdown(false);
                setShowVoiceDropdown(false);
                setTextSize((prev) => prev - 1);
              }}
              style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                background: '#FFCF4F',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <img src={"https://drive.google.com/thumbnail?id=15NdJsuPm_aFSSAp-EEtu5c7-Af-w9Yji&sz=w1000"} alt="Decrease Text Size" style={{ width: '30px', height: '30px' }} />
            </button>
          </div>
        )}
      </div>

    </div>
  );
};

export default Chatbot;
